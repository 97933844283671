import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 2
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"TWO"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Which of the following is not a non-negotiable?"}
        answers={[
          "No physical violence",
          "No put-downs",
          "No cursing",
          "No unsupervised activity",
        ]}
        correctAnswerNumber={2}
        answerDetail={
          "It’s important for coaches to identify and verbalize the behaviors we want to see and hear in the program and avoid terms without a clearly defined meaning."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 2, 6)
